import * as React from "react";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkPrimaryButton, UitkButtonSize } from "@egds/react-core/button";
import { UitkSheet, UitkSheetContent } from "@egds/react-core/sheet";
import { UitkRadioButton, UitkRadioButtonGroup } from "@egds/react-core/radio-button";
import { UitkText } from "@egds/react-core/text";
import { UitkToolbar } from "@egds/react-core/toolbar";

import { useLocalization } from "@shared-ui/localization-context";

import { locKeys } from "components/flexComponents/WizardCruisePWA/l10n";

export enum LOCATION_TYPES {
  origin = "ORIGIN",
  destination = "DESTINATION",
}

export interface LocationsListOption {
  code: string;
  name: string;
}

export interface LocationsListGroup extends LocationsListOption {
  items: LocationsListOption[];
}

export interface Locations3PPDialogProps {
  queryRouteId: string;
  locationType?: LOCATION_TYPES;
  toolbarHeader: string;
  options: LocationsListOption[];
  onDismiss: () => void;
  onDoneButtonClick: (option: LocationsListOption) => void;
}

export const Locations3PPDialog: React.FC<Locations3PPDialogProps> = (props) => {
  const { locationType = LOCATION_TYPES.origin, options } = props;
  const { formatText } = useLocalization();

  const [currentCode, setCurrentCode] = React.useState("");
  const [currentName, setCurrentName] = React.useState("");
  const triggerRef = React.createRef<HTMLElement>();

  const handleDoneButtonClick = () => {
    props.onDoneButtonClick({ code: currentCode, name: currentName });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: code, labels } = event.currentTarget;
    const [label] = labels ?? [];

    setCurrentCode(code);
    setCurrentName(label.textContent as string);
  };

  /**
   * Destinations can be grouped by country however, there's a limitation from the API
   * where a single array is received.
   * Transform array of options to destination groups for easier rendering
   * @param {LocationsListOption[]} destinations Original options from service call
   */
  const optionsAsDestinationGroups = (destinations: LocationsListOption[]) => {
    const destinationGroups: LocationsListGroup[] = [];

    destinations.forEach(({ name, code }) => {
      const isCountry = !name.includes(" - ");

      if (isCountry) {
        const items = [{ name, code }];

        destinationGroups.push({ name, code, items });
      } else {
        destinationGroups[destinationGroups.length - 1].items.push({ name: name.replace(" - ", ""), code });
      }
    });

    return destinationGroups;
  };

  return (
    <UitkSheet type="full" isVisible triggerRef={triggerRef}>
      <UitkToolbar
        toolbarTitle={props.toolbarHeader}
        navigationContent={{
          navIconLabel: formatText(locKeys.dismissDialog),
          onClick: props.onDismiss,
        }}
      />
      <UitkSpacing margin={{ inlinestart: "two" }}>
        <UitkSheetContent>
          {locationType === LOCATION_TYPES.origin && (
            <UitkRadioButtonGroup
              group={`${props.queryRouteId}-group`}
              onChange={handleRadioChange}
              selected={currentCode}
            >
              {props.options.map(({ code, name }) => (
                <UitkRadioButton
                  key={`${props.queryRouteId}-radio-${code}`}
                  id={`${props.queryRouteId}-radio-${code}`}
                  value={code}
                >
                  {name}
                </UitkRadioButton>
              ))}
            </UitkRadioButtonGroup>
          )}

          {locationType === LOCATION_TYPES.destination &&
            optionsAsDestinationGroups(options).map((group) => {
              return (
                <UitkSpacing padding={{ block: "three" }} key={group.name}>
                  <section>
                    <UitkSpacing padding={{ block: "three" }}>
                      <UitkText size={400} weight="bold">
                        {group.name}
                      </UitkText>
                    </UitkSpacing>

                    <UitkRadioButtonGroup
                      group={`${props.queryRouteId}-group`}
                      onChange={handleRadioChange}
                      selected={currentCode}
                    >
                      {group.items.map(({ code, name }: LocationsListOption) => (
                        <UitkRadioButton
                          key={`${props.queryRouteId}-radio-${code}`}
                          id={`${props.queryRouteId}-radio-${code}`}
                          value={code}
                        >
                          {name}
                        </UitkRadioButton>
                      ))}
                    </UitkRadioButtonGroup>
                  </section>
                </UitkSpacing>
              );
            })}
        </UitkSheetContent>
      </UitkSpacing>
      <UitkSpacing padding="three">
        <footer>
          <UitkPrimaryButton
            onClick={handleDoneButtonClick}
            className={`${props.queryRouteId}-confirm-button`}
            size={UitkButtonSize.LARGE}
            isFullWidth
          >
            {formatText(locKeys.dialogDoneButton)}
          </UitkPrimaryButton>
        </footer>
      </UitkSpacing>
    </UitkSheet>
  );
};
